import {
  DEVICES_OVERVIEW_ROUTE_PATH,
  ACCESS_CONTROLS_GROUPS_ROUTE_PATH,
  ACCESS_CONTROLS_POLICIES_ROUTE_PATH,
  SETTINGS_PATH
} from './Routes'

/**
 * The application's primary navigation items
 */
export const NAV_PRIMARY = [
  {
    name: 'Devices',
    path: DEVICES_OVERVIEW_ROUTE_PATH
  },
  {
    name: 'Access Groups',
    path: ACCESS_CONTROLS_GROUPS_ROUTE_PATH
  },
  {
    name: 'Access Policies',
    path: ACCESS_CONTROLS_POLICIES_ROUTE_PATH
  }
]

/**
 * The application's profile navigation items
 */
export const NAV_PROFILE = [
  {
    name: 'Account Settings',
    path: SETTINGS_PATH
  }
]
